import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { ProjectListRowLink } from './ProjectListRow';
import Subhead from './Subhead';

const ProjectListItem = ({ layout, name, slug, subhead }) => {
  const [current, setCurrent] = useState(false);
  const [linkAnimation, setTitleAnimation] = useSpring(() => ({
    from: { xy: [0, 0] },
    to: { xy: [0, 0] },
  }));

  const animateRight = () => setTitleAnimation({ xy: [16, 0] });

  const resetAnimation = () => setTitleAnimation({ xy: [0, 0] });

  const path = slug === '/' ? slug : `/${slug}/`;

  return (
    <ProjectListRowLink
      layout={layout}
      getProps={({ isCurrent }) => setCurrent(isCurrent)}
      to={path}
      pointerEvents={current ? 'no' : ''}
      onMouseEnter={animateRight}
      onMouseLeave={resetAnimation}
    >
      <animated.div
        style={{
          transform: linkAnimation.xy.interpolate(
            (x, y) => `translate3d(${x}px, ${y}px, 0px)`
          ),
        }}
      >
        {current && <LineThrough>{name}</LineThrough>}
        {!current && name}
        {subhead && (
          <>
            {' '}
            <Subhead>{subhead}</Subhead>
          </>
        )}
      </animated.div>
    </ProjectListRowLink>
  );
};

export default ProjectListItem;

const LineThrough = styled.span`
  text-decoration: line-through;
`;
