import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import Grid from './Grid';

const gridColumn = theme.variants('mode', 'position', {
  left_text: { normal: 'left-text-start / left-text-end' },
  right_text: { normal: 'right-text-start / right-text-end' },
  left_inset_text: { normal: 'left-inset-text-start / left-inset-text-end' },
});

const Text = ({
  position = 'left_text',
  children,
  noBottomMargin,
  noTopMargin,
}) => (
  <Grid>
    <PositionedText
      position={position}
      noBottomMargin={noBottomMargin}
      noTopMargin={noTopMargin}
    >
      {children}
    </PositionedText>
  </Grid>
);

export default Text;

const PositionedText = styled.div`
  grid-column: ${gridColumn};
  margin: var(--vertical-space) 0;

  ${props =>
    props.noTopMargin &&
    css`
      margin-top: 0;
    `}

  ${props =>
    props.noBottomMargin &&
    css`
      margin-bottom: 0;
    `}
`;
