import React from 'react';
import Helmet from 'react-helmet';
import '../css/themes.css';

const TitleAndTheme = ({ title, themeClass }) => (
  <Helmet title={title}>
    <body className={themeClass} />
  </Helmet>
);

export default TitleAndTheme;
