import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import Link from 'gatsby-link';

const pointerEvents = theme.variants('mode', 'pointerEvents', {
  default: { normal: 'auto' },
  no: { normal: 'none' },
});

const gridColumn = theme.variants('mode', 'layout', {
  default: { normal: 'auto' },
  left: { normal: 'left-text-start / left-text-end' },
  right: { normal: 'right-text-start / right-text-end' },
  full: { normal: 'left-text-start / right-text-end' },
});

const rowStyles = css`
  display: block;
  padding: 0.75em 0;
  position: relative;
  text-decoration: none;
  grid-column: ${gridColumn};
  pointer-events: ${pointerEvents};
`;

const ProjectListRow = styled.div`
  ${rowStyles}
`;

export const ProjectListRowLink = styled(Link)`
  ${rowStyles}
`;

export default ProjectListRow;

ProjectListRow.defaultProps = {
  pointerEvents: 'default',
  layout: 'default',
};

ProjectListRowLink.defaultProps = {
  pointerEvents: 'default',
  layout: 'default',
};
