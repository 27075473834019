import styled from 'styled-components';
import theme from 'styled-theming';
import {
  comfortableColWidth,
  minColWidth,
  maxColWidth,
  minColWidthPx,
  maxColWidthPx,
} from '../utils/gridWidths';

const minMax = `minmax(${`${minColWidth}px`}, ${`${maxColWidth}px`})`;

const alignItems = theme.variants('mode', 'verticalAlign', {
  default: { normal: 'normal' },
  center: { normal: 'center' },
});

const autoFlow = theme.variants('mode', 'flowDirection', {
  default: { normal: 'row' },
  column: { normal: 'column' },
});

const margin = theme.variants('mode', 'verticalMargin', {
  default: { normal: '0 auto' },
  top: { normal: 'var(--vertical-space) auto 0 auto' },
  bottom: { normal: '0 auto var(--vertical-space) auto' },
  both: { normal: 'var(--vertical-space) auto' },
});

const Grid = styled.div`
  justify-content: center;
  display: grid;
  align-items: ${alignItems};
  grid-auto-flow: ${autoFlow};
  grid-row-gap: var(--vertical-space);
  grid-column-gap: ${maxColWidthPx};
  margin: ${margin};

  @media screen and (max-width: ${`${20 * comfortableColWidth}px`}) {
    grid-column-gap: ${minColWidthPx};
  }

  @media screen and (max-width: ${`${32 * comfortableColWidth}px`}) {
    padding-left: 2px;
    padding-right: 2px;

    grid-template-columns:
      [left-image-start right-image-start offset-triplet-start-1] ${minMax}
      [left-text-start right-text-start left-inset-text-start inset-center-start left-tiny-image-start] ${minMax}
      [] ${minMax}
      [offset-triplet-end-1 offset-triplet-start-2 right-tiny-image-start center-tiny-image-start] ${minMax}
      [left-tiny-image-end] ${minMax}
      ${minMax}
      [offset-triplet-end-2 offset-triplet-start-3 right-tiny-image-end center-tiny-image-end] ${minMax}
      ${minMax}
      [left-text-end right-text-end left-inset-text-end inset-center-end] ${minMax}
      [left-image-end offset-triplet-end-3 grid-end];
  }

  @media screen and (min-width: ${`${32 * comfortableColWidth}px`}) {
    grid-template-columns:
      [left-image-start] ${minMax}
      [left-text-start offset-triplet-start-1] ${minMax}
      [inset-center-start left-tiny-image-start] ${minMax}
      ${minMax}
      [left-inset-text-start offset-triplet-end-1 offset-triplet-start-2] ${minMax}
      ${minMax}
      [left-tiny-image-end center-tiny-image-start] ${minMax}
      [offset-triplet-end-2 offset-triplet-start-3] ${minMax}
      [left-image-end left-text-end right-image-start right-text-start]
      ${minMax}
      ${minMax}
      [offset-triplet-end-3 right-tiny-image-start center-tiny-image-end] ${minMax}
      [left-inset-text-end] ${minMax}
      ${minMax}
      ${minMax}
      [inset-center-end right-tiny-image-end] ${minMax}
      [right-text-end] ${minMax}
      [grid-end];
  }

  @media screen and (min-width: ${`${36 * maxColWidth}px`}) {
    grid-template-columns:
      ${maxColWidthPx}
      [left-image-start] 1fr
      [left-text-start offset-triplet-start-1] ${maxColWidthPx}
      [inset-center-start left-tiny-image-start] ${maxColWidthPx}
      [left-inset-text-start] ${maxColWidthPx}
      [offset-triplet-end-1 offset-triplet-start-2] ${maxColWidthPx}
      [left-tiny-image-end] ${maxColWidthPx}
      [center-tiny-image-start] ${maxColWidthPx}
      [offset-triplet-end-2 offset-triplet-start-3] ${maxColWidthPx}
      [left-image-end left-text-end right-image-start right-text-start]
      ${maxColWidthPx}
      ${maxColWidthPx}
      [center-tiny-image-end left-inset-text-end offset-triplet-end-3] ${maxColWidthPx}
      [right-tiny-image-start] ${maxColWidthPx}
      ${maxColWidthPx}
      ${maxColWidthPx}
      [inset-center-end right-tiny-image-end] ${maxColWidthPx}
      [right-text-end] 1fr
      [grid-end] ${maxColWidthPx};
  }
`;

Grid.defaultProps = {
  autoFlow: 'default',
  verticalMargin: 'default',
};

export default Grid;

export const GridThreeAcross = styled.div`
  display: grid;
  justify-content: center;
  grid-auto-flow: ${autoFlow};
  grid-row-gap: var(--vertical-space);
  grid-column-gap: ${maxColWidthPx};
  margin: ${margin};

  @media screen and (max-width: ${`${20 * comfortableColWidth}px`}) {
    grid-column-gap: ${minColWidthPx};
  }

  @media screen and (max-width: ${`${32 * comfortableColWidth}px`}) {
    max-width: ${`${17 * maxColWidth}px`};
    padding-left: 2px;
    padding-right: 2px;

    grid-template-columns:
      [first-image-start] 1fr
      [first-image-end second-image-start] 1fr
      [second-image-end third-image-start] 1fr
      [third-image-end grid-end];
  }

  @media screen and (min-width: ${`${32 * comfortableColWidth}px`}) {
    max-width: ${`${31 * maxColWidth}px`};

    grid-template-columns:
      [first-image-start] 1fr
      [first-image-end second-image-start] 1fr
      [second-image-end third-image-start] 1fr
      [third-image-end grid-end];
  }

  @media screen and (min-width: ${`${36 * maxColWidth}px`}) {
    max-width: none;

    grid-template-columns:
      ${maxColWidthPx}
      [first-image-start] 1fr
      [first-image-end second-image-start] 1fr
      [second-image-end third-image-start] 1fr
      [third-image-end grid-end] ${maxColWidthPx};
  }
`;

GridThreeAcross.defaultProps = {
  autoFlow: 'default',
  verticalMargin: 'default',
  alignItems: 'default',
};
