import styled from 'styled-components';

const Subhead = styled.span`
  display: block;
  font-size: 16px;

  @media screen and (max-width: 480px) {
    font-size: 1em;
  }
`;

export default Subhead;
