import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import ProjectList from './ProjectList';
import '../css/base.css';

const Layout = ({ children }) => (
  <ThemeProvider theme={{ mode: 'normal' }}>
    {children}
    <ProjectList />
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
