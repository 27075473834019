import React from 'react';
import styled from 'styled-components';
import ProjectListItem from './ProjectListItem';
import ProjectListRow from './ProjectListRow';
import Grid from './Grid';

const ProjectList = () => (
  <ListGrid verticalMargin="top">
    <ProjectListRow layout="left">
      <Header>All the project pages</Header>
    </ProjectListRow>
    <ProjectListItem layout="left" name="Index" slug="/" />
    <ProjectListItem
      layout="left"
      name="Genius Overview"
      slug="geniusoverview"
    />
    <ProjectListItem layout="left" name="Keepsakes" slug="keepsakes" />
    <ProjectListItem layout="left" name="LTC Video Notes" slug="videonotes" />
    <ProjectListItem
      layout="left"
      name="Origami Hue Controller"
      slug="origamihue"
    />
    <ProjectListItem
      layout="left"
      name="Lyric Card Generator"
      slug="lyriccardgenerator"
    />
    <ProjectListItem layout="left" name="Song Stories" slug="songstories" />
  </ListGrid>
);

export default ProjectList;

const ListGrid = styled(Grid)`
  margin-top: calc(var(--vertical-space) * 3);
  padding-bottom: calc(var(--vertical-space) * 3);
  grid-row-gap: unset;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const Header = styled.div`
  font-weight: 900;
`;
